import React from "react";
import {graphql, Link} from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Img from "gatsby-image";

const Press = ({data: {datoCmsPresspage: p}}) => {
  return <Layout>
    <SEO title='Press' seo={p.seoMetaTags}/>
    <div className="font-title text-3xl uppercase text-center tracking-wide w-full my-4">Media Kit</div>
    <div className="flex flex-wrap justify-center items-end">
      {p.mediakit.map(i =>
        <a key={i.originalId} target='_blank' rel="noopener noreferrer" href={i.url} className='mx-4 my-8'>
          <Img fluid={i.fluid} style={{width: 300}} className='border'/>
          <div className="font-sans font-light my-2">{i.title}</div>
        </a>)}
    </div>
    <div className='w-full flex justify-center '>
      <Link to='/press/'
            className='font-sans font-light tracking-wide text-white my-4 p-2 bg-yellow-700 text-center'>Back to
        Press</Link>
    </div>
  </Layout>;
}

export default Press

export const query = graphql`
    query MediaKit {
        datoCmsPresspage {
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            mediakit {
                originalId
                fluid(maxWidth: 300) {
                    ...GatsbyDatoCmsFluid
                }
                url
                title
            }
        }
    }

`